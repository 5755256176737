@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
html,
:root {
  color-scheme: light;
}

/* :root, ::after, ::before, ::backdrop {
  --native-dark-bg-color: #292929;
  --native-dark-bg-image-color: rgba(0, 0, 0, 0.25);
  --native-dark-bg-image-filter: brightness(50%) contrast(200%);
  --native-dark-border-color: #555555;
  --native-dark-box-shadow: 0 0 0 1px rgb(255 255 255 / 10%);
  --native-dark-cite-color: #92de92;
  --native-dark-fill-color: #7d7d7d;
  --native-dark-font-color: #dcdcdc;
  --native-dark-link-color: #8db2e5;
  --native-dark-opacity: 0.85;
  --native-dark-text-shadow: none;
  --native-dark-transparent-color: transparent;
  --native-dark-visited-link-color: #c76ed7
} */

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
}
* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
